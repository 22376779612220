<template>
	<figure>
		<img src="/assets/img/M-1.jpg"
			width="1000" height="1000"
			data-id="41" alt="m" />
	</figure>
	<figure>
		<img src="/assets/img/M-2.jpg"
			width="1000" height="1000"
			data-id="33" alt="m" />
	</figure>
	<figure>
		<img src="/assets/img/M-3.jpg"
			width="1000" height="1000"
			data-id="47" alt="m" />
	</figure>
</template>

<script>
import { newFav } from '@/scripts/scripts';
export default {
	name: 'Logo',
	props: {
		logos: {
			type: Object,
			required: true,
		},
	},
	mounted() {

		var lastId = null;

		var idRange = obj => {

			let newObj = [];

			for ( let i = 0; i < obj.length; i++ ) {

				newObj.push( obj[ i ].dataset.id );

			}

			return newObj

		}

		var randomProperty = ( obj, id ) => {

			let newObj = [];

			for ( let i = 0; i < obj.length; i++ ) {

				if ( Array.isArray( id ) ) {
					
					if ( !id.includes( obj[ i ].id.toString() ) ) {

						newObj.push( obj[ i ] );

					}

				} else {

					if ( obj[ i ].dataset.id !== Number( id ) ) {

						newObj.push( obj[ i ] );

					}

				}

			}
			
			let keys = Object.keys( newObj );
			let length = keys.length;
			let randomObj = newObj[ keys[ length * Math.random() << 0 ] ];
			
			return randomObj;
			
		};

		clearInterval( interval );

		var interval = setInterval( () => {

			let imgs = document.querySelectorAll( "header figure img" );

			let ids = idRange( imgs );

			let img = randomProperty( imgs, lastId );
			let randomM = randomProperty( this.logos, ids );
			let randomSrc = randomM.url;

			img.src = randomSrc;

			newFav( img.src );

			lastId = randomM.id;
			img.dataset.id = lastId;

		}, 3000 );

	}
}
</script>

<style>
	header figure {
		display: inline-block;
		margin: 0;
		height: 6rem;
		width: 6rem;
	}
	header figure img {
		height: 100%;
		width: auto;
	}
</style>