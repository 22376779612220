import { createWebHistory, createRouter } from "vue-router";
import Project from "@/views/Project.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:url",
      name: "project",
      component: Project,
    }
  ]
});

export default router;