<template>
    <template v-if="( !$isMobile() && !isTablet ) || ( isTablet && screen.orientation === 'landscape' )">
        <div v-if="this.project[ 0 ]">
        <template v-for="(row, i) in this.project[ 0 ].gallery" :key="row.id">
            <template v-if="!row.img">
                <div
                    :data-id="row.id"
                    :style="`
                        margin: ` + ( row.fullscreen === true ? 0 : `5rem 0` ) + `;
                `">
                    <Iframe :project="row"/>
                </div>
            </template>
            <template v-else>
                <template v-if="row.img.mime.includes( 'video' )">
                    <Video :project="row"/>
                </template>
                <template v-else>
                    <figure
                        :class="i < 3 || row.img.mime === 'image/gif' ? null : 'lazy'"
                        :data-id="row.id"
                        :style="`
                            margin: ` + ( row.fullscreen === true ? 0 : `5rem 0` ) + `;
                    `">
                        <Img
                            :lazy="i < 3 ? false : true"
                            :project="row"
                            :alt="row.description ? row.description : `Image du projet ${ this.project[ 0 ].title }`"
                            :style="`
                                width: ` + ( row.fullscreen === true ? 100 : row.width ) + `%;
                                ` + ( row.fullscreen === true ? null : `margin-left: ` + row.position + `%; transform: translateX( -` + row.position + `% );` ) + `
                            `" />
                        <figcaption
                            v-if="row.description" v-html="row.description"
                            :style="`
                                width: ` + ( row.fullscreen === true ? 100 : row.width ) + `%;
                                ` + ( row.fullscreen === true ? null : `margin-left: ` + row.position + `%; transform: translateX( -` + row.position + `% );` ) + `
                            `"></figcaption>
                    </figure>
                </template>
            </template>
        </template>
        </div>
        <aside>
            <article v-if="this.project[ 0 ]">
                <div v-if="this.project[ 0 ].description" v-html="markdownToHtml"></div>
                <div>
                    <h2 v-if="this.project[ 0 ].title" v-html="this.project[ 0 ].title"></h2>
                    <p v-if="this.project[ 0 ].categories.length !== 0">
                        <template v-for="( cat, i ) in this.project[ 0 ].categories" :key="cat.id">
                            {{ cat.name }}{{ i != Object.keys( this.project[ 0 ].categories ).length - 1 ? ', ' : null }}
                        </template>
                    </p>
                    <p v-if="this.project[ 0 ].year" v-html="this.project[ 0 ].year"></p>
                </div>
            </article>
        </aside>
    </template>
    <template v-else-if="$isMobile() || ( isTablet && screen.orientation === 'portrait' )">
        <swiper
            id="swiper__main"
            :slides-per-view="1"
            :pagination="true"
            @swiper="onSwiper"
            @slideChange="onSlideChange" >
            <swiper-slide class="swiper__project">
                <div v-if="this.project[ 0 ]">
                <template v-for="(row, i) in this.project[ 0 ].gallery" :key="row.id">
                    <template v-if="!row.img">
                        <div
                            :data-id="row.id"
                            :style="`
                                margin: ` + ( row.fullscreen === true ? 0 : `5rem 0` ) + `;
                        `">
                            <Iframe :project="row"/>
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="row.img.mime.includes( 'video' )">
                            <Video :project="row"/>
                        </template>
                        <template v-else>
                            <figure
                                :class="i < 3 || row.img.mime === 'image/gif' ? null : 'lazy'"
                                :data-id="row.id">
                                <Img
                                    :lazy="i < 3 ? false : true"
                                    :project="row"
                                    :alt="row.description ? row.description : `Image du projet ${ this.project[ 0 ].title }`" />
                                <figcaption v-if="row.description" v-html="row.description" ></figcaption>
                            </figure>
                        </template>
                    </template>
                </template>
                </div>
            </swiper-slide>
            <swiper-slide class="swiper__project__about">
                <aside v-if="this.project[ 0 ]" id="about">
                    <header>
                        <h2>{{ this.project[ 0 ].title }}{{ this.project[ 0 ].year ? " • " + this.project[ 0 ].year : null }}</h2>
                    </header>
                    <div v-if="this.project[ 0 ].description" v-html="markdownToHtml"></div>
                    <div>
                        
                        <p v-if="this.project[ 0 ].categories.length !== 0">
                            <template v-for="( cat, i ) in this.project[ 0 ].categories" :key="cat.id">
                                {{ cat.name }}{{ i != Object.keys( this.project[ 0 ].categories ).length - 1 ? ', ' : null }}
                            </template>
                        </p>
                    </div>
                </aside>
            </swiper-slide>
        </swiper>
    </template>
</template>

<script>
const homeRedirection = () => {
    
    const base_url = window.location.origin
    window.location.href = `${ base_url }`;
    window.location.replace( `${ base_url }` );

}

import { useScreen } from 'vue-screen';

import { orthoTypo, figureIntersectionObserver } from '@/scripts/scripts';
import Video from '../components/Video.vue';
import Iframe from '../components/Iframe.vue';
import Img from '../components/Img.vue';

import marked from 'marked';

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
import 'swiper/swiper.min.css';

import SwiperCore, { Pagination } from 'swiper';
SwiperCore.use([Pagination]);
import 'swiper/modules/pagination/pagination.min.css';

export default {
	name: 'Project',
    components: {
        Video,
        Iframe,
        Img,

        Swiper,
        SwiperSlide,
    },
    inheritAttrs: false,
	props: {
		project: {
			type: Object,
			required: true,
		},
		projects: {
			type: Array,
			required: true,
		},
	},
    computed: {

        isTablet() {

        return this.touch && !this.$isMobile();

        },

        markdownToHtml() {

            let str = this.project[0].description;
                str = marked( str );
                str = str.replaceAll( /<img/ig, '<img loading="lazy"');
                str = str.replaceAll( 'href="tab:', 'target="_blank" href="');
                str = str.replaceAll( '\n', '<br>');
                str = str.replaceAll( '</p><br><p>', '</p><p>');
				str = orthoTypo( str );


            return str;

        },

    },
    setup() {

		const onSwiper = swiper => console.log( swiper );
		const onSlideChange = () => console.log( 'slide change' );

        const screen = useScreen();
        const touch = screen.touch;

		return {
            
			onSwiper,
			onSlideChange,
            screen,
            touch,

		};

    },
    mounted() {

        const url = this.$route.params.url;
        var isProject = false;
        
        if ( this.projects.length !== 0 ) {

            if ( this.project.length === 0 ) {

                for ( let i = 0; i < this.projects.length; i++ ) {

                    let e = this.projects[ i ];
                        e = e[ 'title' ].normalize( 'NFD' ).replaceAll( /\p{Diacritic}/gu, '' ).toLowerCase().replaceAll( ' ', '-' );

                    if ( url === e ) isProject = true;
                    
                }

                if ( !isProject ) homeRedirection();

            }
            
        }

        const aside = document.querySelector( "aside" );

        aside.addEventListener( "click", function() {

            if ( aside.classList.contains( "open" ) ) {
                aside.classList.remove( "open" )
            } else {
                aside.classList.add( "open" )
            }

        } );

        setTimeout(() => {

            const section = document.querySelector( "section:not(#about) *:not(aside)" );

            section.addEventListener( "click", function() {

                if ( aside.classList.contains( "open" ) ) aside.classList.remove( "open" );

            } );
            
        }, 2000);

        const lazyLoadingFigures =  document.querySelectorAll( ".lazy" );

        if ( lazyLoadingFigures.length !== 0 && window.IntersectionObserver ) {

            lazyLoadingFigures.forEach( img => figureIntersectionObserver.observe( img ) );
            
        }
        
    },
    updated() {

        const lazyLoadingFigures =  document.querySelectorAll( ".lazy" );

        if ( lazyLoadingFigures.length !== 0 && window.IntersectionObserver ) {

            lazyLoadingFigures.forEach( img => figureIntersectionObserver.observe( img ) );
            
        }

    },

}
</script>