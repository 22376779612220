<template>
  {{ bodyClass }}
  <template v-if="( !$isMobile() && !isTablet ) || ( isTablet && screen.orientation === 'landscape' )">
    <nav>
      <HeaderLeft :about="about"/>
      <ProjectList :projects="projects"/>
    </nav>
    <main>
      <HeaderRight :about="about"/>
      <About :about="this.about"/>
      <RouterView v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <section v-if="project">
            <component :is="Component" :project="project" :projects="projects" class="view" />
          </section>
        </transition>
      </RouterView>
    </main>
    <template v-if="touch !== true"><svg id="cursor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120"><circle cx="60" cy="60" r="50"/></svg></template>
  </template>
  <template v-else-if="$isMobile() || ( isTablet && screen.orientation === 'portrait' )">
    <HeaderLeft :about="about"/>
    <section class="section section__main">
      <HomeMobile :about="this.about" :projects="projects"/>
    </section>
    <section class="section section__project">
      <RouterView v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <template v-if="project">
            <component :is="Component" :project="project" :projects="projects" class="view" />
          </template>
        </transition>
      </RouterView>
    </section>
  </template>
</template>

<script>
import { useScreen } from 'vue-screen';
const screen = useScreen();

import { normalizeUrl, decode_utf8, setUnits } from '@/scripts/scripts';
import { ref } from '@vue/reactivity';
const main = document.querySelector( "#main" );

import HeaderLeft from './components/HeaderLeft.vue';
import HeaderRight from './components/HeaderRight.vue';
import ProjectList from './components/ProjectList.vue';
import About from './components/About.vue';

import HomeMobile from './components/HomeMobile.vue';

export default {
  name: 'Main',
  inheritAttrs: false,

  components: {
    HeaderLeft,
    HeaderRight,
    ProjectList,
    About,
    HomeMobile,
  },

  setup () {
    let about = ref( [] );
    let projects = ref( [] );

    const touch = screen.touch;

    return {
      about,
      projects,
      screen,
      touch,
    };
  },

  watch: {

    'screen.orientation'() {

      const title = document.querySelector( 'header main' );
      document.documentElement.style.setProperty( '--nav-width', `calc( 21rem + ${ title.offsetWidth + 5 }px )` );

      if( this.isTablet === true && screen.orientation === 'landscape' ) main.classList.remove( 'tablet--portrait' );

      if( this.touch && !this.$isMobile() ) this.$forceUpdate();

    }

  },

  computed: {

    isTablet() {

      return this.touch && !this.$isMobile();

    },

    project() {

      if ( this.$route.path !== '/' ) {

        let arr = this.projects.filter( x => Number( x.id ) === Number( this.$route.params.id ) );

        if ( arr.length === 0 )  {

          let routePath = this.$route.path;
          let n = routePath.lastIndexOf( '/' );

              routePath = routePath.substring( n + 1 );
              routePath = decode_utf8( routePath );

          arr = this.projects.filter( x => decode_utf8( normalizeUrl( x.title ) ) === routePath );

        }

        return arr;
        
      } else { return null }

    },

    bodyClass() {

      if ( this.$route.path !== '/' ) {

        main.classList.add( "project" );

      } else {

        main.classList.remove( "project" );

      }

      if ( this.touch === true ) main.classList.add( 'touch' );

      if ( this.isTablet === true ) main.classList.add( 'tablet' );

      if ( this.isTablet === true && screen.orientation === 'portrait' ) main.classList.add( 'tablet--portrait' );

      if ( this.$isMobile() === true ) main.classList.add( 'mobile' );

      return null;

    }

  },

  mounted() {

    const fetchAbout = fetch( `${ this.herokUrl }/about` )
      .then( ( res ) => res.json() )
      .then( ( data ) => {
        this.about = data;
      });

    const fetchProject = fetch( `${ this.herokUrl }/projects` )
      .then( ( res ) => res.json() )
      .then( ( data ) => {
        this.projects = data;
      });
    
    const allData = Promise.all( [ fetchAbout, fetchProject ] );

    allData;

    const cursor = document.querySelector( '#cursor' );
    const cursorMove = ( e ) => {
          
      cursor.style.top = `${ e.pageY }px`;
      cursor.style.left = `${ e.pageX }px`;
      
    }

    if ( this.$isMobile() !== true ) {

      document.onmousemove = e => cursorMove( e );

    }

    const hoverInit = () => {

      let aArr = document.querySelectorAll( "a" );

      for ( let i = 0; i < aArr.length; i++ ) {

        const a = aArr[i];
        a.addEventListener( "mouseenter", () => cursor.classList.add( "mini" ) );
        a.addEventListener( "mouseleave", () => cursor.classList.remove( "mini" ) );

      }

    };

    if( this.touch ) {

      setUnits();

      window.addEventListener( 'resize', () => setUnits() );

    }

    if ( ( !this.$isMobile() && !this.isTablet ) || ( this.isTablet && screen.orientation === 'landscape' ) ) {

      const link = document.querySelector( "nav header div a" );

      link.addEventListener( "click", function() {

        document.querySelector( "section#about" ).scrollTop = 0;
        document.querySelector( "section#about" ).documentElement.scrollTop = 0;

      } );

      const titleInit = new Promise( ( resolve, reject ) => {

        setTimeout(() => {

          const title = document.querySelector( 'header main' );

          if ( title.offsetWidth !== 0 ) {

            document.documentElement.style.setProperty( '--nav-width', `calc( 21rem + ${ title.offsetWidth + 5 }px )` );
            resolve( "loaded" );

          } else {

            document.documentElement.style.setProperty( '--nav-width', `35.5rem` );
            reject( "No title width" );

          }
          
        }, 1000);

      });

      titleInit.then( () => {

        if ( this.touch !== true ) {
          hoverInit();
        }

      } ).catch( error => {

        console.log( 'error:', error );

      } ).finally( () => {

        setTimeout( () => { main.classList.remove( "unloaded" ) }, 800 );

        setTimeout( () => { main.classList.remove( "loading" ) }, 2400 );

      } );

    } else {

      setTimeout( () => { main.classList.remove( "unloaded" ) }, 800 );

      setTimeout( () => { main.classList.remove( "loading" ) }, 2400 );

    }

  }
  
}
</script>
