import { createApp } from 'vue';

import Main from './Main.vue';
import router from './router';

import VueMobileDetection from "vue-mobile-detection";

const main = createApp( Main );

let url = 'https://mmm-db.herokuapp.com';

main.config.globalProperties.herokUrl = url;

main.use( router );
main.use( VueMobileDetection );

main.mount( '#main' );