<template>
    <ul>
        <template v-for="project in ordered" :key="project.order">
            <li class="project-li" :data-id="project.id">
                <router-link :to="{
                    name: 'project',
                    params: {
                        url: project.title.normalize( 'NFD' ).replaceAll( /\p{Diacritic}/gu, '' ).toLowerCase().replaceAll( ' ', '-' ),
                        id: project.id
                    }
                }" >
                    <div>
                    <template v-if="project.thumbnail.mask !== null">
                        <figure
                            :data-mask="project.thumbnail.mask.url"
                            :style="`
                                -webkit-mask-image: url(` + project.thumbnail.mask.url + `);
                                mask-image: url(` + project.thumbnail.mask.url + `);
                                mask-repeat: no-repeat;
                            `" >
                            <img loading="lazy"
                                :src="project.thumbnail.image.formats.small.url"
                                :width="project.thumbnail.image.formats.small.width"
                                :height="project.thumbnail.image.formats.small.height"
                                :alt="project.thumbnail.image.caption !== null ? project.thumbnail.image.caption : project.title"/>
                        </figure>
                    </template>
                    <template v-else>
                        <figure>
                            <img loading="lazy"
                                :src="project.thumbnail.image.formats.small.url"
                                :width="project.thumbnail.image.formats.small.width"
                                :height="project.thumbnail.image.formats.small.height"
                                :alt="project.thumbnail.image.caption !== null ? project.thumbnail.image.caption : project.title"/>
                        </figure>
                    </template>
                    </div>
                    <h2 v-if="project.title" v-html="project.title"></h2>
                    <p><template v-for="( cat, i ) in project.categories" :key="cat.id">{{ cat.name }}{{ i != Object.keys( project.categories ).length - 1 ? ', ' : '&nbsp;•&nbsp;' }}</template>{{ project.year }}</p>
                </router-link>
            </li>
        </template>
    </ul>
</template>

<script>
import { svg } from '@/scripts/scripts';
import { ref } from '@vue/reactivity';
export default {
	name: 'Projects',
    components: {
    },
	props: {
		projects: {
			type: Array,
			required: true,
		},
	},
    computed: {
        ordered() {
            let obj = this.projects, order = {}, res = {}, j = 0;
            for (let i = 0; i < obj.length; i++) {
                const e = obj[ i ];
                const key = e[ 'order' ];
                if ( key !== null || key < 0 ) {
                    order[ j ] = e;
                    j++;
                }
            }
            const orderLth = Object.keys( order ).length;
            for (let i = 0; i < orderLth; i++) {
                const e = order[ i ];
                const key = e[ 'order' ] - 1;
                res[ orderLth - key + 1 ] = e;
            }
            return res;
        }
    },
    setup() {
        let date = ref( new Date() );

        if( navigator.userAgent.indexOf( "Firefox" ) != -1 ) navigator.userAgent.indexOf( "Firefox" );

        return {
            date,
        }
    },
    mounted() {

        setTimeout(() => {

            const links = document.querySelectorAll( "nav ul li a" );

            for( let i = 0; i < links.length; i++ ) {
                const link = links[ i ];

                link.addEventListener( "click", function() {

                    const section = document.querySelector( "section:not( #about ) > div" );

                    if ( section ) {

                        section.scrollTop = 0;
                        if( section.documentElement ) section.documentElement.scrollTop = 0;
                        
                    }

                } );
                
            }
            
        }, 1200);

        setTimeout( () => {

            const li = document.querySelectorAll( ".project-li" );
            
            for( let i = 0; i < li.length; i++ ) {

                const e = li[ i ].querySelector( "figure" );

                svg( e );
                
            }
            
        }, 2000 );
        
    }
}
</script>