<template>
    <header>
        <div>
            <p v-if="about.instagram"><a :href="about.instagram" target="_blank">instagram</a></p>
            <p v-if="about.contact"><a :href="'mailto:'+about.contact">contact</a></p>
        </div>
        <div v-if="about.adress" v-html="markedAdress"></div>
    </header>
</template>

<script>

import { markDown } from '@/scripts/scripts';

export default {
	name: 'Header',
    components: {
    },
	props: {
		about: {
			type: Object,
			required: true,
		},
	},
    computed: {

        markedAdress() {

            let adress = this.about.adress;
            return markDown( adress );

        }

    }
}
</script>