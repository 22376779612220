<template>
    <article>
        <div
            :style="`
                width: ` + ( project.fullscreen === true ? 100 : project.width ) + `%;
                padding-top: ` + ( project.fullscreen === true ? 62.5 : project.width * 5 / 8 ) + `%;
                ` + ( project.fullscreen === true ? null : `margin-left: ` + project.position + `%; transform: translateX( -` + project.position + `% );` ) + `
            `">
            <iframe
                :src="project.url"
                :title="project.description"
                :width="project.width" :height="project.width * 315 / 560" frameborder="0"
                allow="fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
    </article>
</template>

<script>
export default {
	name: 'Iframe',
	props: {
		project: {
			type: Array,
			required: true,
		},
	},
}
</script>