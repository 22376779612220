<template>
    <article
        :data-id="project.id"
        :style="`
            margin: ` + ( project.fullscreen === true ? 0 : `5rem 0` ) + `;
    `">
        <video controls
            :style="`
                width: ` + ( project.fullscreen === true ? 100 : project.width ) + `%;
                ` + ( project.fullscreen === true ? null : `margin-left: ` + project.position + `%; transform: translateX( -` + project.position + `% );` ) + `
            `">
            <source :src="project.img.url" :type="project.img.mime">
            <p v-if="project.description">{{ project.description }}</p>
        </video>
        <p v-if="project.description"
            :style="`
                width: ` + ( project.fullscreen === true ? 100 : project.width ) + `%;
                ` + ( project.fullscreen === true ? null : `margin-left: ` + project.position + `%; transform: translateX( -` + project.position + `% );` ) + `
        `">{{ project.description }}</p>
    </article>
</template>

<script>
export default {
	name: 'Video',
	props: {
		project: {
			type: Array,
			required: true,
		},
	},
}
</script>