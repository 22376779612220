const orthoTypo = str => {

	if ( typeof str === "object" ) str = str.innerHTML;
  
  const sp = '&#8239;';

  const stArr = [
    [ '« ', `«${sp}` ],
    [ ' »', `${sp}»` ],
    [ ' ;', `${sp};` ],
    [ ' :', `${sp}:` ],
    [ ' !', `${sp}!` ],
    [ ' ?', `${sp}?` ],
    [ ' + ', `${sp}+${sp}` ],
    [ ' - ', `${sp}-${sp}` ],
    [ ' x ', `${sp}x${sp}` ],
    [ ' = ', `${sp}=${sp}` ],
    [ ' < ', `${sp}<${sp}` ],
    [ ' > ', `${sp}>${sp}` ],
    [ ' ≤ ', `${sp}≤${sp}` ],
    [ ' ≥ ', `${sp}≥${sp}` ],
    [ ' % ', `${sp}%${sp}` ],
    [ ' / ', `${sp}/${sp}` ],
  ];

  for ( let i = 0; i < stArr.length; i++ ) {
    const e = stArr[ i ];
    if ( str.includes( e[ 0 ] ) ) str = str.replaceAll( e[ 0 ], e[ 1 ] );
  }

  return str;
  
}

const markDown = str => {

    const strArr = str.split( /\r?\n|\r/g );

    var newStr = "";

    for ( let i = 0; i < strArr.length; i++ ) {

        if ( i !== 0 ) newStr += "<br/>";

        newStr += strArr[ i ];
        
    }

    return newStr;

};

const normalizeUrl = e => {

    e = e.normalize( 'NFD' );
    e = e.replaceAll( /\p{Diacritic}/gu, '' );
    e = e.toLowerCase().replaceAll( ' ', '-' );

    return e;

};

const decode_utf8 = str => {

    return decodeURIComponent( str );

}

const newFav = src => {

    var link = document.querySelector( "link[rel*='icon']" ) || document.createElement( 'link' );
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = src;
    document.getElementsByTagName( 'head' )[ 0 ].appendChild( link );

}

const figureIntersectionObserver = new IntersectionObserver(

    allObserved => {

        allObserved.forEach( observed => {

            if ( !observed.isIntersecting ) return;

            const fig = observed.target;
            const img = fig.querySelector( "img" );

            img.src = img.dataset.src;
            delete img.dataset.src;
            fig.classList.remove( "lazy" );

            figureIntersectionObserver.unobserve( fig );

        });

    }

);

const svg = e => {

    fetch( e.dataset.mask )
        .then( response => response.text() )
        .then( text => ( new DOMParser() ).parseFromString( text, "image/svg+xml" ) )
        .then( parsedSvg => {

            const xmlns = parsedSvg.documentElement.attributes.xmlns.value;

            let el = document.createElementNS( xmlns, "svg" );
                el.setAttributeNS( null, "viewBox", parsedSvg.documentElement.attributes.viewBox.value );
                el.setAttributeNS( null, "width", parsedSvg.documentElement.width.animVal.value );
                el.setAttributeNS( null, "height", parsedSvg.documentElement.height.animVal.value );

            var path = document.createElementNS( xmlns, "path" );
                path.setAttributeNS( null, 'stroke', "#000" );
                path.setAttributeNS( null, 'stroke-width', 10 );
                path.setAttributeNS( null, 'd', parsedSvg.documentElement.firstChild.attributes.d.value );
                path.setAttributeNS( null, 'fill', "none" );
                path.setAttributeNS( null, 'opacity', 1.0 );

            // el.id = `mask-${ id }`;

            el.appendChild( path );

            e.parentNode.appendChild( el );

    });

}

const setUnits = () => {

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

};

export { orthoTypo, markDown, normalizeUrl, decode_utf8, newFav, figureIntersectionObserver, svg, setUnits };