<template>
	<swiper
		id="swiper__main"
		:slides-per-view="1"
		:pagination="true"
		@swiper="onSwiper"
		@slideChange="onSlideChange" >
		<swiper-slide class="swiper__about">
			<aside id="about">
				<header>
					<p v-if="about.instagram"><a :href="about.instagram" target="_blank">instagram</a></p>
					<p v-if="about.contact"><a :href="'mailto:'+about.contact">contact</a></p>
					<div v-if="about.adress" v-html="markedAdress"></div>
				</header>
				<div v-if="about.about" v-html="markdownToHtml"></div>
			</aside>
		</swiper-slide>
		<swiper-slide class="swiper__projects">
			<ul>
				<template v-for="project in ordered" :key="project.order">
					<li class="project-li" :data-id="project.id">
						<router-link :to="{
							name: 'project',
							params: {
								url: project.title.normalize( 'NFD' ).replaceAll( /\p{Diacritic}/gu, '' ).toLowerCase().replaceAll( ' ', '-' ),
								id: project.id
							}
						}" >
							<div>
							<template v-if="project.thumbnail.mask !== null">
								<figure
									:data-mask="project.thumbnail.mask.url"
									:style="`
										-webkit-mask-image: url(` + project.thumbnail.mask.url + `);
										mask-image: url(` + project.thumbnail.mask.url + `);
										mask-repeat: no-repeat;
									`" >
									<img loading="lazy"
										:width="project.thumbnail.width" :height="project.thumbnail.height"
										:src="project.thumbnail.image.formats.large.url"
										:alt="project.thumbnail.image.caption !== null ? project.thumbnail.image.caption : project.title" />
								</figure>
							</template>
							<template v-else>
								<figure>
									<img loading="lazy"
										:width="project.thumbnail.width" :height="project.thumbnail.height"
										:src="project.thumbnail.image.formats.large.url"
										:caption="project.thumbnail.image.caption !== null ? project.thumbnail.image.caption : project.title" />
								</figure>
							</template>
							</div>
							<h2 v-if="project.title" v-html="project.title"></h2>
						</router-link>
					</li>
				</template>
			</ul>
		</swiper-slide>
	</swiper>
</template>

<script>

import { orthoTypo, markDown, svg } from '@/scripts/scripts';
import marked from 'marked';

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
import 'swiper/swiper.min.css';

import SwiperCore, { Pagination } from 'swiper';
SwiperCore.use( [ Pagination ] );
import 'swiper/modules/pagination/pagination.min.css';

export default {

	name: 'HomeFlicker',

    components: {
		Swiper,
		SwiperSlide,
    },

	props: {
		projects: {
			type: Object,
			required: true,
		},
		about: {
			type: Object,
			required: true,
		},
	},

	computed: {

		markdownToHtml() {

			let str = this.about.about;
				str = marked( str );
				str = str.replaceAll( /<img/ig, '<img loading="lazy"' );
                str = str.replaceAll( 'href="tab:', 'target="_blank" href="');
                str = str.replaceAll( '\n', '<br>');
                str = str.replaceAll( '</p><br><p>', '</p><p>');
                str = str.replaceAll( ' href="#"', '');
                str = `${ str }<footer>Site développé par <a target="_blank" href="https://www.fromager-florian.fr/">Florian Fromager.</a></footer>`;
				str = orthoTypo( str );

			return str;

		},

        ordered() {
            let obj = this.projects, order = {}, res = {}, j = 0;
            for (let i = 0; i < obj.length; i++) {
                const e = obj[ i ];
                const key = e[ 'order' ];
                if ( key !== null || key < 0 ) {
                    order[ j ] = e;
                    j++;
                }
            }
            const orderLth = Object.keys( order ).length;
            for (let i = 0; i < orderLth; i++) {
                const e = order[ i ];
                const key = e[ 'order' ] - 1;
                res[ orderLth - key ] = e;
            }
            return res;
        },

        markedAdress() {

            let adress = this.about.adress;
            return markDown( adress );

        },
    },

    setup() {

		const onSwiper = swiper => console.log( swiper );
		const onSlideChange = () => console.log( 'slide change' );

		return {

			onSwiper,
			onSlideChange,

		};

    },
    mounted() {

        setTimeout( () => {

            const li = document.querySelectorAll( ".project-li" );
            
            for( let i = 0; i < li.length; i++ ) {

                const e = li[ i ].querySelector( "figure" );

                svg( e );
                
            }
            
        }, 2000 );
        
    }

}
</script>