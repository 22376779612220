<template>
    <header>
        <div>
            <router-link to="/">
                <Logo :logos="about.logo"/>
            </router-link>
        </div>
        <main>
            <router-link to="/">
                <h1 v-if="about.title" v-html="about.title"></h1>
            </router-link>
            <p v-if="about.subtitle" v-html="about.subtitle"></p>
        </main>
    </header>
</template>

<script>
import Logo from './Logo.vue';

export default {
	name: 'Header',
    components: {
        Logo,
    },
	props: {
		about: {
			type: Object,
			required: true,
		},
	},
}
</script>