<template>
    <section v-if="about.about" v-html="markdownToHtml" id="about"></section>
</template>

<script>

import marked from 'marked';
import { orthoTypo } from '@/scripts/scripts';
export default {
	name: 'About',
	props: {
		about: {
			type: Object,
			required: true,
		},
	},

    computed: {

        markdownToHtml() {

            let str = this.about.about;
                str = marked( str );
                str = str.replaceAll( /<img/ig, '<img loading="lazy"');
                str = str.replaceAll( 'href="tab:', 'target="_blank" href="');
                str = str.replaceAll( '\n', '<br>');
                str = str.replaceAll( '</p><br><p>', '</p><p>');
                str = `${ str }<footer>Site développé par <a target="_blank" href="https://www.fromager-florian.fr/">Florian Fromager.</a></footer>`;
				str = orthoTypo( str );

            return str;

        }

    }
}
</script>