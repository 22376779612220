<template>
	<template v-if="project.img.mime === 'image/gif'">
		<img loading="lazy"
			:width="project.img.width" :height="project.img.height"
			:src="project.img.url"
			:alt="alt !== null ? alt : null"
			:style="style" />
	</template>
	<template v-else>
		<img v-if="lazy !== true" loading="lazy"
			:width="project.img.width" :height="project.img.height"
			:src="project.img.formats.large.url"
			:alt="alt !== null ? alt : null"
			:style="style" />
		<img v-else
			:width="project.img.width" :height="project.img.height"
			:src="project.img.formats.small.url"
			:data-src="project.img.formats.large.url"
			:alt="alt !== null ? alt : null"
			:style="style" />
	</template>
</template>

<script>
export default {
	name: 'Img',
	props: {
		lazy: {
			type: Boolean,
		},
		project: {
			type: Object,
			required: true,
		},
		alt: {
			type: String,
		},
		style: {
			type: String,
		},
	},
}
</script>